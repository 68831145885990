@font-face {
  font-family: 'Capriola';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/capriola-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Capriola Regular'), local('Capriola-Regular'),
       url('../assets/fonts/capriola-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/capriola-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/capriola-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/capriola-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/capriola-v7-latin-regular.svg#Capriola') format('svg'); /* Legacy iOS */
}

html {
  font-family: "Capriola", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
}

nav {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  width: 200px;
  z-index: 1;

  color: #000000;
  background-color: #f2f2f2;
  overflow: auto;
}

nav ul li.pure-menu-item {
  font-size: 0.8em;
}

nav ul li.pure-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

nav ul li.pure-menu-item.pure-menu-link {
  white-space: normal;
  word-break: break-all;
  cursor: pointer;

  color: #000000;
}

nav ul li.pure-menu-item > img {
  display: inline-block;
  vertical-align: middle;
}

nav ul li.pure-menu-item:hover > img {
  filter: invert(1);
}

nav p {
  padding: 1em;
  margin: 0;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.5);
}

nav h3 {
  padding: 1em;
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  color: rgba(0, 0, 0, 0.5);
}

nav ul li.pure-menu-item.pure-menu-link:hover {
  color: #ffffff;
}

main {
  margin-left: 200px;
  padding: 1em;
  z-index: 0;
}

@media (max-width: 768px) {
  nav {
    display: none;
  }

  main {
    margin-left: 0;
    margin-top: 2em;
  }

  .expanded nav {
    display: block;
  }
}

@media print {
  nav {
    display: none !important;
  }

  main {
    margin-left: 0;
  }
}
