.hamburger {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  background: #000000;
  background: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  z-index: 1;
  width: 2em;
  height: auto;
  padding: 2.1em 1.6em;
  cursor: pointer;
}

.hamburger:hover, .hamburger:focus {
  background: #000000;
}

.hamburger span {
  position: relative;
  display: block;
}

.hamburger span, .hamburger span:before, .hamburger span:after {
  background-color: #ffffff;
  width: 100%;
  height: 0.2em;
}

.hamburger span:before, .hamburger span:after {
  position: absolute;
  margin-top: -0.6em;
  content: " ";
}

.hamburger span:after {
  margin-top: 0.6em;
}

.expanded {
}

@media (max-width: 768px) {
  .hamburger.expanded {
    left: 200px;
  }
}

@media print {
  .hamburger {
    display: none;
  }
}
